import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { StyledTextInput } from "../StyledComponents/StyledInputs";
import toastr from "toastr";
import {
  makeGETRequestOnUserService,
  makePOSTRequestOnUserService,
  makePUTRequestOnUserService,
} from "../../Api/api";
import moment, { Moment } from "moment";

export interface KartaDetailsInterface {
  panCardOfKarta: File | null;
  panPassword?: string;
  deedOfDeclarationOfHuf: File | null;
  deedPassword?: string;
  dateOfIncorporationOfHuf: Moment | null;
  fullNameOfKarta: string;
  panCardBase64: string;
  deedDeclarationBase64: string;
}

const inferFileType = (base64String: string) => {
  if (!base64String) return null;

  if (base64String.startsWith("/9j/")) return "image/jpeg"; // JPEG
  if (base64String.startsWith("iVBORw0KGgo")) return "image/png"; // PNG
  if (base64String.startsWith("JVBER")) return "application/pdf"; // PDF
  return null; // Unknown type
};

const getBase64Url = (base64String: string) => {
  const mimeType = inferFileType(base64String);
  return mimeType ? `data:${mimeType};base64,${base64String}` : "";
};

const KartaDetails = (props: any) => {
  const { userUUID, areHufDocsUploaded } = props;
  const [kartaDetails, setKartaDetails] = useState<KartaDetailsInterface>({
    panCardOfKarta: null,
    panPassword: "",
    deedOfDeclarationOfHuf: null,
    deedPassword: "",
    dateOfIncorporationOfHuf: null,
    fullNameOfKarta: "",
    panCardBase64: "",
    deedDeclarationBase64: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }
    makeGETRequestOnUserService(`/user/huf_docs/download/${userUUID}/`)
      .then((resp) => {
        if (active && resp.status == 200) {
          const data = resp.data;
          setKartaDetails({
            panCardOfKarta: null,
            panPassword: data.pan_card_plain_text_password,
            deedOfDeclarationOfHuf: null,
            deedPassword: data.deed_of_declaration_plain_text_password,
            dateOfIncorporationOfHuf: data.date_of_incorporation,
            fullNameOfKarta: data.name_of_karta,
            panCardBase64: data.pan_card_base64,
            deedDeclarationBase64: data.deed_of_declaration_base64,
          });
        }
      })
      .catch((error) => toastr.error(error))
      .finally(() => {
        active && setLoading(false);
      });
    return () => {
      active = false;
    };
  }, [loading]);

  const handleCreateKarta = () => {
    let formData = new FormData();
    formData.append("uuid", userUUID);
    formData.append("pan_card", kartaDetails.panCardOfKarta!);
    formData.append(
      "deed_of_declaration",
      kartaDetails.deedOfDeclarationOfHuf!
    );
    formData.append(
      "date_of_incorporation",
      moment(kartaDetails.dateOfIncorporationOfHuf!).format("YYYY-MM-DD")
    );
    formData.append("name_of_karta", kartaDetails.fullNameOfKarta!);
    formData.append(
      "huf_pan_card_plain_text_password",
      kartaDetails.panPassword!
    );
    formData.append(
      "huf_deed_of_declaration_plain_text_password",
      kartaDetails.deedPassword!
    );
    makePOSTRequestOnUserService(`/user/huf_docs/upload/`, formData)
      .then((resp) => {
        if (resp.status === 201) {
          toastr.success("Karta Added Successfully!");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleUpdateKarta = () => {
    makePUTRequestOnUserService(`user//${kartaDetails}/`, {
      userId: userUUID,
      //   name: nomineeDetails?.name,
      //   date_of_birth: moment(nomineeDetails?.date_of_birth, "YYYY-MM-DD").format(
      //     "DD/MM/YYYY"
      //   ),
      //   relationship: nomineeDetails?.relationship,
      //   mobileNumber: nomineeDetails?.mobileNumber,
    })
      .then((resp) => {
        if (resp.status === 201) {
          toastr.success("Nominee Details Updated Successfully!");
          setLoading(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  return (
    <Box sx={{ maxWidth: ["90%", "80%"], mx: "auto", my: 5 }}>
      {loading ? (
        <CircularProgress color="error" />
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: 2,
          }}
        >
          <Box sx={{ display: "flex", gap: 2 }}>
            {kartaDetails.panCardBase64 && (
              <Box>
                <strong>PAN Card Preview:</strong>
                {inferFileType(kartaDetails.panCardBase64) ===
                "application/pdf" ? (
                  <iframe
                    src={getBase64Url(kartaDetails.panCardBase64)}
                    width="100%"
                    height="500px"
                    title="PAN Card PDF"
                    style={{ border: "none", borderRadius: "5px" }}
                  />
                ) : (
                  <img
                    src={getBase64Url(kartaDetails.panCardBase64)}
                    alt="PAN Card"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: 5,
                    }}
                  />
                )}
              </Box>
            )}

            {kartaDetails.deedDeclarationBase64 && (
              <Box>
                <strong>Deed Declaration Preview:</strong>
                {inferFileType(kartaDetails.deedDeclarationBase64) ===
                "application/pdf" ? (
                  <iframe
                    src={getBase64Url(kartaDetails.deedDeclarationBase64)}
                    width="100%"
                    height="500px"
                    title="Deed Declaration PDF"
                    style={{ border: "none", borderRadius: "5px" }}
                  />
                ) : (
                  <img
                    src={getBase64Url(kartaDetails.deedDeclarationBase64)}
                    alt="Deed Declaration"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      borderRadius: 5,
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
          <Grid container spacing={3}>
            {Object.keys(kartaDetails)
              .filter(
                (item) =>
                  !["panCardBase64", "deedDeclarationBase64"].includes(item)
              )
              .map((item, index) => (
                <Grid item xs={12} sm={6} key={index}>
                  <FormControl fullWidth variant="standard">
                    <InputLabel shrink htmlFor={item}>
                      {item
                        .replace(/([A-Z])/g, " $1")
                        .replace(/^./, (str) => str.toUpperCase())}
                    </InputLabel>
                    <StyledTextInput
                      id={`${item}_${index}`}
                      name={item}
                      type={
                        item === "dateOfIncorporationOfHuf"
                          ? "date"
                          : [
                              "panCardOfKarta",
                              "deedOfDeclarationOfHuf",
                            ].includes(item)
                          ? "file"
                          : "text"
                      }
                      value={
                        item === "dateOfIncorporationOfHuf"
                          ? kartaDetails.dateOfIncorporationOfHuf
                            ? moment(
                                kartaDetails.dateOfIncorporationOfHuf
                              ).format("YYYY-MM-DD")
                            : ""
                          : [
                              "panCardOfKarta",
                              "deedOfDeclarationOfHuf",
                            ].includes(item)
                          ? undefined // Avoid setting value for file inputs
                          : kartaDetails[item as keyof KartaDetailsInterface]
                      }
                      onChange={(event) => {
                        const target = event.target as HTMLInputElement;
                        if (
                          item === "panCardOfKarta" ||
                          item === "deedOfDeclarationOfHuf"
                        ) {
                          const file = target.files?.[0] || null;
                          setKartaDetails((prev) => ({
                            ...prev,
                            [item]: file,
                          }));
                        } else if (item === "dateOfIncorporationOfHuf") {
                          const value = target.value;
                          setKartaDetails((prev) => ({
                            ...prev,
                            [item]: moment(value),
                          }));
                        } else {
                          const value = target.value;
                          setKartaDetails((prev) => ({
                            ...prev,
                            [item]: value,
                          }));
                        }
                      }}
                    />
                  </FormControl>
                </Grid>
              ))}
          </Grid>
          <Button
            variant="contained"
            size="large"
            fullWidth={false}
            onClick={() =>
              areHufDocsUploaded ? handleUpdateKarta() : handleCreateKarta()
            }
          >
            {areHufDocsUploaded
              ? "Update Karta Details"
              : "Verify Karta Details"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default KartaDetails;
