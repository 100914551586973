import React, { useEffect, useContext } from "react";
import toastr from "toastr";
import Paper from "@material-ui/core/Paper";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { appContext } from "../App";
import {
  getAllIssuerMasters,
  deleteIssuerMasterByUUID,
  getAllIssuerTypes,
  uploadBulkIssuerNames,
  getAllIssuerNames,
  makePOSTRequest,
  makeGETRequest,
} from "../Api/api";
import AddIssuerMasterModal from "../components/AddIssuerMasterModal";
import DeleteModal from "../components/DeleteModal";
import Button from "@material-ui/core/Button";
import AddIssuerTypeDIalog from "../components/AddIssuerTypeDIalog";
import AddIssuerNameDialog from "../components/AddIssuerNameDialog";
import BulkUploadModal from "../components/BulkUploadModal";
import IssuerMasterArticleDialog from "../components/IssuerMasterArticleDialog";
import EditIcon from "@material-ui/icons/Edit";
import SMESTAnalysisReportDialog from "../components/SMESTAnalysisReport/SMESTAnalysisReportDialog";
import AssignmentIcon from "@material-ui/icons/Assignment";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid } from "@material-ui/core";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DownloadIcon from "@mui/icons-material/Download";
import YouTubeIcon from "@mui/icons-material/YouTube";
import AddIssuerMasterYoutubeLinks from "../components/AddIssuerMasterYoutubeLinks";
import { Box } from "@mui/material";

const IssuerMaster = (props) => {
  const { authUser, setAuthUser } = useContext(appContext);
  const [issuerMasters, setIssuerMasters] = React.useState([]);
  const [showDeleteIssuerMasterModal, setShowDeleteIssuerMasterModal] =
    React.useState(false);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);
  const [showAddIssuerMasterModal, setShowAddIssuerMasterModal] =
    React.useState(null);
  const [showAddIssuerMasterYoutubeLinks, setShowAddIssuerMasterYoutubeLinks] =
    React.useState(null);
  const [showBulkUploadDialog, setShowBulkUploadDialog] = React.useState(false);
  const [issuerTypes, setIssuerTypes] = React.useState([]);
  const [showIssuerTypeDialog, setShowIssuerTypeDialog] = React.useState(false);
  const [issuerNames, setIssuerNames] = React.useState([]);
  const [issuerMasterArticleDialog, setIssuerMasterArticleDialog] =
    React.useState(false);
  const [currentIssuerMasterUUID, setCurrentIssuerMasterUUID] =
    React.useState("");
  const [showIssuerNameDialog, setShowIssuerNameDialog] = React.useState(false);
  const [toBeEdited, setToBeEdited] = React.useState(null);
  const [selectedIssuerMaster, setSelectedIssuerMaster] = React.useState(null);
  const [showFileUploadDialog, setShowFileUploadDialog] = React.useState(false);
  const [currentlySelectedIssuerMasterId, setCurrentlySelectedIssuerMasterId] =
    React.useState("");
  const [SMESTAnalysisReportDialogState, setSMESTAnalysisReportDialogState] =
    React.useState(false);
  const [analysisReport, setAnalysisReport] = React.useState(null);

  const dataGridCells = [
    { field: "serial", headerName: "Serial", width: 190, editable: false },
    {
      field: "issuerType",
      headerName: "Issuer Type",
      width: 300,
      editable: false,
    },
    {
      field: "issuerName",
      headerName: "Issuer Name",
      width: 250,
      editable: false,
    },
    {
      field: "issuerCode",
      headerName: "Issuer Code",
      width: 300,
      editable: false,
    },
    {
      field: "isAnalysisReportSubmitted",
      headerName: "Is Report Uploaded",
      width: 300,
      editable: false,
    },
    {
      field: "Articles",
      headerName: "Articles",
      width: 250,
      editable: false,
      renderCell: () => {
        return <AssignmentIcon Articles />;
      },
    },

    {
      field: "Reports",
      headerName: "Reports",
      width: 250,
      editable: false,
      renderCell: (row) => {
        if (row.row.isAnalysisReportSubmitted) {
          return (
            <Button variant="outlined" color="primary">
              View Report
            </Button>
          );
        } else {
          return (
            <Button variant="outlined" color="primary">
              Create Report
            </Button>
          );
        }
      },
    },
    {
      field: "isReferenced",
      headerName: "Is Referenced",
      width: 250,
      editable: false,
    },
    {
      field: "uploadProspectus",
      headerName: "Upload Prospectus",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: (row) => {
        if (row.row.isProspectusUploaded) {
          return (
            <DownloadIcon
              color="primary"
              fontSize="medium"
              style={{
                float: "left",
                "margin-top": "10px",
                "margin-left": "14px",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return (
            <CloudUploadIcon
              color="primary"
              fontSize="medium"
              style={{
                float: "left",
                "margin-top": "10px",
                "margin-left": "14px",
                cursor: "pointer",
              }}
            />
          );
        }
      },
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,

      renderCell: () => {
        return (
          <EditIcon
            color="primary"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
    {
      field: "youtube",
      headerName: "Youtube",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: () => {
        return (
          <YouTubeIcon
            color="error"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,
      renderCell: () => {
        return (
          <DeleteIcon
            color="primary"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
  ];

  function addSerialNumberToIssuerMasters(users) {
    let formatted = [];
    for (let iterator = 0; iterator < users.length; iterator++) {
      let userWithSerial = users[iterator];
      userWithSerial.serial = iterator + 1;
      formatted.push(userWithSerial);
    }
    return formatted;
  }

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllIssuerMasters()
        .then((response) => {
          if (response && response.status === 200) {
            response.data
              ? setIssuerMasters(addSerialNumberToIssuerMasters(response.data))
              : setIssuerMasters([]);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  const handleAddIssuerTypeOnClick = (event) => {
    event.preventDefault();
    getAllIssuerTypes()
      .then((response) => {
        if (response && response.status === 200) {
          setIssuerTypes(response.data);
          setShowIssuerTypeDialog(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleAddIssuerMasterOnClick = (event) => {
    event.preventDefault();
    let requestURL = `/admin/issuer_name/issuer_type/list/`;
    makeGETRequest(requestURL).then((response) => {
      if (response && response.status === 200) {
        setIssuerNames(response.data.issuerNames);
        setIssuerTypes(response.data.issuerTypes);
        setShowAddIssuerMasterModal(true);
      }
    });
  };

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  const handleBulkUploadDialog = (event) => {
    event.preventDefault();
    setShowBulkUploadDialog(true);
  };

  function downloadFile(base64Content) {
    const blob = dataURItoBlob(base64Content);
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  }

  function downloadProspectus(issuerMasterUUID) {
    makeGETRequest(
      `/admin/issuer_master/prospectus/download/${issuerMasterUUID}/`
    )
      .then((response) => {
        downloadFile(response.data);
      })
      .catch((error) => toastr.error(error));
  }

  function handleUploadProspectusOnClick(row) {
    if (row.isProspectusUploaded) {
      downloadProspectus(row.uuid);
      return;
    }
    setShowFileUploadDialog(!showFileUploadDialog);
    setCurrentlySelectedIssuerMasterId(row.uuid);
  }

  const handleSMESTAnaylsisReportDialog = (row) => {
    if (row.isAnalysisReportSubmitted) {
      let requestURL = `/admin/issuer_master/get/analysisReport/${row.uuid}/`;
      makeGETRequest(requestURL)
        .then((response) => {
          if (response && response.status === 200) {
            setSMESTAnalysisReportDialogState(true);
            setAnalysisReport(response.data);
            setSelectedIssuerMaster(row);
          }
        })
        .catch((error) => toastr.error(error));
    } else {
      setSMESTAnalysisReportDialogState(true);
      setSelectedIssuerMaster(row);
    }
  };

  const renderBulkUploadDialogButton = () => {
    return (
      <Button
        className="btnClass"
        variant="outlined"
        onClick={(event) => handleBulkUploadDialog(event)}
        color="primary"
        style={{
          float: "right",
          margin: "25px 10px 15px",
          cursor: "pointer",
        }}
      >
        Bulk Upload Issuer Name
      </Button>
    );
  };

  const renderAddIssuerTypeButton = () => {
    return (
      <Button
        className="btnClass"
        variant="outlined"
        onClick={(event) => handleAddIssuerTypeOnClick(event)}
        color="primary"
        style={{
          float: "right",
          margin: "25px 10px 15px",
          cursor: "pointer",
        }}
      >
        Add Issuer Type
      </Button>
    );
  };

  const handleAddIssuerName = (event) => {
    event.preventDefault();
    getAllIssuerNames()
      .then((response) => {
        if (response && response.status === 200) {
          setIssuerNames(response.data);
          setShowIssuerNameDialog(true);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const renderAddIssuerNemeButton = () => {
    return (
      <Button
        className="btnClass"
        variant="outlined"
        onClick={(event) => handleAddIssuerName(event)}
        color="primary"
        style={{
          float: "right",
          "margin-top": "25px",
          "margin-right": "50px",
          cursor: "pointer",
        }}
      >
        Add Issuer Name
      </Button>
    );
  };

  function getFilteredIssuerType(issuerType) {
    if (issuerTypes.length) {
      return issuerTypes.filter((type) => type.type === issuerType)[0];
    } else {
      return "";
    }
  }

  function updateCurrentState(event, issuerMasters) {
    event.preventDefault();
    setIssuerMasters(issuerMasters);
  }

  const renderAddIssuerMasterModal = () => {
    let condition = toBeEdited === null || toBeEdited === undefined;
    return (
      <AddIssuerMasterModal
        openModal={showAddIssuerMasterModal}
        onCloseModal={closeAddIssueMasterModal}
        issuerTypes={issuerTypes}
        issuerNames={issuerNames}
        issuerType={
          toBeEdited ? getFilteredIssuerType(toBeEdited.issuerType) : ""
        }
        issuerCode={condition ? "" : toBeEdited.issuerCode}
        issuerName={condition ? "" : toBeEdited.issuerName}
        toBeEdited={toBeEdited}
        issuerMasterUUID={condition ? "" : toBeEdited.uuid}
        mode={condition ? "Create" : "Edit"}
        condition={condition}
        image={condition ? "" : toBeEdited.downloadedImage}
        updateIssuerMaster={updateCurrentState}
      />
    );
  };

  const renderAddIssuerMasterYoutubeLinks = () => {
    return (
      <AddIssuerMasterYoutubeLinks
        openModal={showAddIssuerMasterYoutubeLinks}
        onCloseModal={closeAddIssueMasterYoutubeLinks}
        issuerId={currentlySelectedIssuerMasterId}
      />
    );
  };

  const renderAddButtonForIssuerMaster = () => {
    return (
      <AddCircleIcon
        onClick={(event) => handleAddIssuerMasterOnClick(event)}
        color="primary"
        fontSize="large"
        style={{
          float: "right",
          margin: "25px 10px 15px",
          cursor: "pointer",
        }}
      />
    );
  };

  const handleIssuerMasterOnclickDelete = (row) => {
    setToBeDeleted(row);
    setShowDeleteIssuerMasterModal(true);
  };

  const handleEditIconOnClick = (row) => {
    let requestURL = `/admin/issuer_master/get/${row.uuid}/`;
    makeGETRequest(requestURL).then((response) => {
      if (response && response.status === 200) {
        makeGETRequest("/admin/issuer_name/issuer_type/list/")
          .then((r) => {
            if (r && r.status === 200) {
              setIssuerTypes(r.data.issuerTypes);
              setIssuerNames(r.data.issuerNames);
              setToBeEdited(response.data);
              setShowAddIssuerMasterModal(true);
            }
          })
          .catch((error) => toastr.error(error));
      } else {
        toastr.error("Issuer Master does not exist");
      }
    });
  };

  const handleYoutubeIconOnClick = (row) => {
    setShowAddIssuerMasterYoutubeLinks(true);
    setCurrentlySelectedIssuerMasterId(row.uuid);
  };

  const handleOnCloseModal = () => {
    setShowDeleteIssuerMasterModal(false);
    getAllIssuerMasters()
      .then((response) => {
        if (response && response.status === 200 && response.data) {
          setIssuerMasters(response.data);
          toastr.success("Deleted Issuer master successfully !");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleViewArticlesOnclick = (row) => {
    setCurrentIssuerMasterUUID(row.uuid);
    setIssuerMasterArticleDialog(true);
  };

  const closeDeleteModal = () => {
    setShowDeleteIssuerMasterModal(false);
    setToBeDeleted(null);
  };

  const deleteIssuerMaster = (row) => {
    if (authUser && authUser.uuid) {
      deleteIssuerMasterByUUID(toBeDeleted.uuid)
        .then((response) => {
          if (
            response &&
            (response.status === 200 || response.status === 204)
          ) {
            setIssuerMasters(response.data);
            setShowDeleteIssuerMasterModal(false);
            setToBeDeleted(null);
            toastr.success("Deleted Issuer master successfully !");
          }
        })
        .catch((error) =>
          toastr.error(
            "Invalid value for issuer master uuid or it is being referenced in a security master"
          )
        );
    }
  };

  const closeAddIssueMasterModal = (event) => {
    event.preventDefault();
    setShowAddIssuerMasterModal(false);
    setToBeEdited(null);
    if (issuerMasters !== undefined) {
      setIssuerMasters(issuerMasters);
    }
  };

  const closeAddIssueMasterYoutubeLinks = (event) => {
    event.preventDefault();
    setShowAddIssuerMasterYoutubeLinks(false);
  };

  const handleIssuerTypeOnClose = () => {
    setShowIssuerTypeDialog(false);
  };

  const handleOnCloseForBulkUpload = (event, issuerNames) => {
    if (typeof issuerNames === undefined) {
      setIssuerNames(issuerNames);
    }
    setShowBulkUploadDialog(false);
  };

  const handleSubmitForIssuerNameFileUpload = (event, selectedFile) => {
    event.preventDefault();
    if (authUser && authUser.uuid && selectedFile !== null) {
      let formData = new FormData();
      formData.append("csvFile", selectedFile);
      uploadBulkIssuerNames(formData)
        .then((response) => {
          if (
            response &&
            (response.status === 201 || response.status === 200) &&
            response.data.length
          ) {
            setShowBulkUploadDialog(false);
            setIssuerNames(response.data);
            toastr.success("Issuer types were uploaded successfully !");
          }
        })
        .catch((error) => toastr.error(error));
    }
  };

  const handleOnCloseArticleDialog = (event) => {
    event.preventDefault();
    setCurrentIssuerMasterUUID("");
    setIssuerMasterArticleDialog(false);
  };

  const closeAnalysisReportDialog = (event) => {
    event.preventDefault();
    setSMESTAnalysisReportDialogState(false);
    setAnalysisReport(null);
    setSelectedIssuerMaster(null);
  };

  const renderSMESTAnalysisReportDialog = () => {
    if (selectedIssuerMaster !== null) {
      return (
        <SMESTAnalysisReportDialog
          show={SMESTAnalysisReportDialogState}
          onCloseDialog={closeAnalysisReportDialog}
          selectedIssuerMaster={selectedIssuerMaster}
          report={analysisReport}
          updateReportInParentComponent={(updatedReport) => {
            setAnalysisReport(updatedReport);
          }}
        />
      );
    }
  };

  const renderIssuerMasterArticleDialog = () => {
    return (
      <IssuerMasterArticleDialog
        show={issuerMasterArticleDialog}
        onCloseDialog={handleOnCloseArticleDialog}
        issuerMasterUUID={currentIssuerMasterUUID}
      />
    );
  };

  const renderBulkUploadDialog = () => {
    return (
      <BulkUploadModal
        show={showBulkUploadDialog}
        onCloseModal={handleOnCloseForBulkUpload}
        submitHandler={handleSubmitForIssuerNameFileUpload}
        heading={"Import Issuer Names"}
      />
    );
  };

  const closeAddIssuerNameDialog = (event) => {
    event.preventDefault();
    setShowIssuerNameDialog(false);
    getAllIssuerNames()
      .then((response) => {
        if (response && response.status === 200) {
          setIssuerNames(response.data);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const renderIssuerNameDialog = () => {
    return (
      <AddIssuerNameDialog
        show={showIssuerNameDialog}
        onCloseModal={closeAddIssuerNameDialog}
        issuerNames={issuerNames}
      />
    );
  };

  const renderIssuerTypeDIalog = () => {
    return (
      <AddIssuerTypeDIalog
        show={showIssuerTypeDialog}
        onCloseModal={handleIssuerTypeOnClose}
        issuerTypes={issuerTypes}
      />
    );
  };

  const renderDeleteModal = () => {
    return (
      <DeleteModal
        primaryText="Delete Issuer Master"
        secondaryText="Are you sure you want to delete this Issuer Master ?"
        isOpen={showDeleteIssuerMasterModal}
        handleClose={closeDeleteModal}
        confirmDelete={deleteIssuerMaster}
      />
    );
  };

  const currentlySelected = (params) => {
    if (
      params.field === "delete" &&
      handleIssuerMasterOnclickDelete(params.row)
    );
    else if (params.field === "edit" && handleEditIconOnClick(params.row));
    else if (
      params.field === "Reports" &&
      handleSMESTAnaylsisReportDialog(params.row)
    );
    else if (
      params.field === "Articles" &&
      handleViewArticlesOnclick(params.row)
    );
    else if (
      params.field === "uploadProspectus" &&
      handleUploadProspectusOnClick(params.row)
    );
    else if (
      params.field === "youtube" &&
      handleYoutubeIconOnClick(params.row)
    );
  };

  function uploadFileHandler(event, selectedFile) {
    event.preventDefault();
    let formData = new FormData();
    formData.append("issuerMasterUUID", currentlySelectedIssuerMasterId);
    formData.append("prospectus", selectedFile);
    makePOSTRequest("/admin/issuer_master/prospectus/upload/", formData)
      .then((response) => {
        setCurrentlySelectedIssuerMasterId("");
        setShowFileUploadDialog(!showFileUploadDialog);
        setIssuerMasters(response.data);
        toastr.success("Prospectus uploaded successfully!");
      })
      .catch((error) =>
        toastr.error("There was an error while uploading prospectus!")
      );
  }

  return (
    <Grid className="issuerMasterDataGrid">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>
                Issuer Master
              </div>
            </Grid>

            {renderAddButtonForIssuerMaster()}
            {renderAddIssuerNemeButton()}
            {renderAddIssuerTypeButton()}
            {renderBulkUploadDialogButton()}
            {renderDeleteModal()}
            {showAddIssuerMasterModal ? renderAddIssuerMasterModal() : ""}
            {showAddIssuerMasterYoutubeLinks
              ? renderAddIssuerMasterYoutubeLinks()
              : ""}
            {renderIssuerTypeDIalog()}
            {renderIssuerNameDialog()}
            {renderBulkUploadDialog()}
            {renderIssuerMasterArticleDialog()}
            {renderSMESTAnalysisReportDialog()}
          </Grid>
        </Grid>
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            px: [2, 10],
          }}
        >
          <DataGrid
            pagination
            className="issuerMasterDataGrid abd"
            components={{ Toolbar: GridToolbar }}
            rows={issuerMasters}
            columns={dataGridCells}
            onCellClick={currentlySelected}
            getRowId={(row) => row.uuid}
          />
        </Box>
      </Paper>
      {/* <IdleTimoutConfig /> */}
      {showFileUploadDialog ? (
        <BulkUploadModal
          show={showFileUploadDialog}
          onCloseModal={(event) => {
            setShowFileUploadDialog(!showFileUploadDialog);
            setCurrentlySelectedIssuerMasterId("");
          }}
          submitHandler={uploadFileHandler}
          heading="Upload Prospectus"
        />
      ) : (
        ""
      )}
    </Grid>
  );
};

export default IssuerMaster;
