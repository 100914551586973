import React, { useState } from "react";
import {
  Grid,
  Card,
  CardContent,
  Typography,
  CardActions,
  IconButton,
  Chip,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import GenericDialog from "../Dialog/GenericDialog";
import {
  makeDELETERequestOnUserServiceWithQueryParameter,
  makePUTRequestOnUserService,
} from "../../Api/api";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import BankDetailsInformationGrid from "./BankDetailsInformationGrid";
import documentPlaceHolderImage from "../../images/documentPlaceHolder.jpg";
import GetAppIcon from "@mui/icons-material/GetApp";
import { downloadBase64 } from "../../Helpers/helpers";

const toastr = require("toastr");
export default function KYCBankDetailsTab(props: any) {
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [selectedForDelete, setSelectedForDelete] = useState<string>("");
  const [showEditBankDetailsDialog, setShowEditBankDetailsDialog] =
    useState<boolean>(false);
  const [currentlySelectedForEdit, setCurrentlySelectedForEdit] =
    useState<any>(null);
  const [editMode, setEditMode] = useState<boolean>(false);
  const initialState = {
    bankName: "",
    beneficiaryName: "",
    accountNumber: "",
    ifscCode: "",
    branchName: "",
  };
  const [newBankDetails, setNewBankDetails] = useState(initialState);

  function getUpdatedBankDetailsObject(detail: any) {
    return {
      bankName: detail.bank_name,
      beneficiaryName: detail.beneficiary_name,
      accountNumber: detail.account_number,
      ifscCode: detail.ifsc_code,
      branchName: detail.branch_name,
    };
  }

  function BankDetailList({ bankDetails }: any) {
    var details = null;
    if (
      typeof bankDetails === "object" &&
      !Array.isArray(bankDetails) &&
      bankDetails !== null
    ) {
      details = [bankDetails];
    } else {
      details = bankDetails;
    }

    const handleSetDefault = (bankDetail: any) => {
      let payload = {
        user_id: props.currentUser.uuid,
        account_number: bankDetail.account_number,
        is_default: "True",
      };
      makePUTRequestOnUserService(
        "/kyc/bank_detail/update_default_bank_details/",
        payload
      )
        .then((response) => {
          if (response?.status === 200) {
            toastr.success(
              "Bank details set as default! Pls close and click on eye icon again to view it set as default."
            );
          }
        })
        .catch((error) => toastr.error(error));
    };

    return (
      <Grid container spacing={2}>
        {details &&
          details.map((bankDetail: any) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={bankDetail.account_number}
              sx={{ margin: 4 }}
            >
              <Card sx={{ minWidth: 275, height: "100%" }}>
                <img
                  src={
                    bankDetail.image
                      ? `data:image/png;base64, ${bankDetail.image}`
                      : documentPlaceHolderImage
                  }
                  alt="Bank Logo"
                  style={{
                    width: "100%",
                    maxHeight: "150px",
                    objectFit: "cover",
                  }}
                />
                <Typography
                  sx={{ fontSize: 14, ml: 2 }}
                  color="text.primary"
                  gutterBottom
                >
                  Password - {bankDetail.is_password_protected || "NA"}
                </Typography>
                <CardContent>
                  <Typography
                    sx={{ fontSize: 14 }}
                    color="text.secondary"
                    gutterBottom
                  >
                    {bankDetail.account_number || "-"}
                  </Typography>
                  <Typography variant="h5" component="div">
                    {bankDetail.bank_name || "-"}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="text.secondary">
                    {bankDetail.beneficiary_name || "-"}
                  </Typography>
                  <Typography variant="body2">
                    {bankDetail.branch_name || "-"}
                  </Typography>
                </CardContent>
                <CardActions>
                  <IconButton
                    aria-label="edit"
                    onClick={(event) => {
                      setCurrentlySelectedForEdit(
                        getUpdatedBankDetailsObject(bankDetail)
                      );
                      setEditMode(true);

                      setShowEditBankDetailsDialog(true);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  <IconButton
                    aria-label="delete"
                    onClick={(event) => {
                      setShowDeleteModal(!showDeleteModal);
                      setSelectedForDelete(bankDetail.account_number);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>

                  <IconButton
                    aria-label="download"
                    onClick={() =>
                      downloadBase64(
                        bankDetail.image,
                        bankDetail.image_filename
                      )
                    }
                  >
                    <GetAppIcon />
                  </IconButton>
                  <Chip
                    label={bankDetail.is_default ? "Default" : "Set Default"}
                    color="success"
                    variant={bankDetail.is_default ? "filled" : "outlined"}
                    {...(!bankDetail.is_default && {
                      onClick: () => handleSetDefault(bankDetail),
                    })}
                  />
                </CardActions>
              </Card>
            </Grid>
          ))}
      </Grid>
    );
  }

  function AddBankDetailsIcon() {
    return (
      <Grid item xs="auto">
        <AddCircleIcon
          color="primary"
          fontSize="large"
          id="add-circle-id"
          onClick={(event) => {
            setShowEditBankDetailsDialog(true);
          }}
          style={{ position: "fixed", top: 120, right: 50, zIndex: 1000 }}
        />
      </Grid>
    );
  }

  function deleteBankDetails(event: any) {
    event.preventDefault();

    makeDELETERequestOnUserServiceWithQueryParameter(
      `/kyc/bank_detail/delete/${props.currentUser.uuid}/${selectedForDelete}/`
    )
      .then((response) => {
        if (response?.status === 204) {
          toastr.success("Deleted bank details !");
          setShowDeleteModal(!showDeleteModal);

          props.nullifyBankDetails(event, selectedForDelete);
        }
      })
      .catch((error) => toastr.error(error));
  }

  if (props?.bankDetails) {
    return (
      <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
        <Grid container alignItems="center" spacing={3}>
          <Grid item xs="auto">
            <AddBankDetailsIcon />
          </Grid>

          <BankDetailList bankDetails={props?.bankDetails} />
          {showDeleteModal ? (
            <GenericDialog
              show={showDeleteModal}
              heading="Delete Bank Details"
              inputProps="Are you sure you want to delete these bank details ?"
              onClose={(event: any) => {
                event.preventDefault();
                setShowDeleteModal(!showDeleteModal);
              }}
              handleSubmit={(event: any) => {
                event.preventDefault();
                deleteBankDetails(event);
              }}
            />
          ) : (
            ""
          )}
          {showEditBankDetailsDialog ? (
            <GenericDialog
              fullWidth={true}
              show={showEditBankDetailsDialog}
              heading={editMode ? "Update Bank Details" : "Create Bank Details"}
              inputProps={
                <BankDetailsInformationGrid
                  bankDetails={
                    editMode ? currentlySelectedForEdit : newBankDetails
                  }
                  saveBankDetails={props.saveBankDetails}
                  addBankDetails={props.addBankDetails}
                />
              }
              onClose={(event: any) => {
                event.preventDefault();
                setShowEditBankDetailsDialog(!showEditBankDetailsDialog);
                if (editMode) {
                  setEditMode(false);
                }
              }}
              showSubmitButton={false}
              handleSubmit={(event: any) => {
                event.preventDefault();
                setShowEditBankDetailsDialog(!showEditBankDetailsDialog);
                if (editMode) {
                  props.saveBankDetails();
                  setEditMode(false);
                }
              }}
            />
          ) : (
            ""
          )}
        </Grid>
      </Grid>
    );
  } else {
    return (
      <>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <AddBankDetailsIcon />
          </Grid>
        </Grid>
        {showEditBankDetailsDialog ? (
          <GenericDialog
            fullWidth={true}
            show={showEditBankDetailsDialog}
            heading={editMode ? "Update Bank Details" : "Create Bank Details"}
            inputProps={
              <BankDetailsInformationGrid
                bankDetails={
                  editMode ? currentlySelectedForEdit : newBankDetails
                }
                saveBankDetails={props.saveBankDetails}
                addBankDetails={props.addBankDetails}
              />
            }
            onClose={(event: any) => {
              event.preventDefault();
              setShowEditBankDetailsDialog(!showEditBankDetailsDialog);
              if (editMode) {
                setEditMode(false);
              }
            }}
            showSubmitButton={false}
            handleSubmit={(event: any) => {
              event.preventDefault();
              setShowEditBankDetailsDialog(!showEditBankDetailsDialog);
              if (editMode) {
                setEditMode(false);
              }
            }}
          />
        ) : (
          ""
        )}
      </>
    );
  }
}
