import React from "react";
import { makeGETRequest, makePOSTRequest } from "../../Api/api";
import { IPOOrderList } from "./Interfaces";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import { Grid, TextField, Button } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import GenericDialog from "../../components/Dialog/GenericDialog";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { ipoOrdersDataGridCells } from "./helpers";
import Ticker from "../../components/Ticker/Ticker";
import FullScreenDialog from "../../components/Dialog/FullScreenDialog";
import IPOOrderDetailsDialogBody from "./IPOOrderDetailsDialogBody";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import { getIPOAPIBaseURL } from "./helpers";
import Box from "@mui/material/Box";

const toastr = require("toastr");
const moment = require("moment");

export default function IPOOrders() {
  let baseUrl = getIPOAPIBaseURL();

  const [ipoOrders, setIpoOrders] = React.useState<IPOOrderList[]>([]);
  const [showScripidDialog, setShowScripidDialog] =
    React.useState<boolean>(true);
  const [scripIds, setScripIds] = React.useState<string[]>([]);
  const [selectedScripId, setSelectedScripId] = React.useState<string | null>(
    ""
  );
  const [showIPOOrderDetailsDialog, setShowIPOOrderDetailsDialog] =
    React.useState<boolean>(false);
  const [uuid, setUUID] = React.useState("");
  const [exchangeMessage, setExchangeMessage] = React.useState<string>("");
  const [exchangeTime, setExchangeTime] = React.useState<any>("");
  const [showExchangeMessageTicker, setShowExchangeMessageTicker] =
    React.useState<boolean>(true);
  const [showExchangeTimeTicker, setShowExchangeTimeTicker] =
    React.useState<boolean>(false);
  const [showEditOrderDialog, setShowEditOrderDialog] =
    React.useState<boolean>(false);
  const [currentlySelectedRow, setCurrentlySelectedRow] =
    React.useState<any>(null);
  const [showSpinner, setShowSpinner] = React.useState(false);
  const [lastCalledOn, setLastCalledOn] = React.useState("");

  //TODO - Will need it later.
  // React.useEffect(() => {
  //   makeGETRequest(baseUrl + "/ipo/exchmsg_ipo/")
  //     .then((resp) => {
  //       setExchangeMessage(resp.data[0].exchmessage);
  //     })
  //     .catch((error) => toastr.error(error));
  // }, []);

  // React.useEffect(() => {
  //   makeGETRequest(baseUrl + "/ipo/exchtime_ipo/")
  //     .then((resp) => {
  //       setExchangeTime(resp.data);
  //       setShowExchangeTimeTicker(true);
  //     })
  //     .catch((error) => toastr.error(error));
  // }, []);

  React.useEffect(() => {
    makeGETRequest(baseUrl + "/ipo/scripids/list/")
      .then((resp) => {
        setScripIds([
          ...resp.data.map((record: any) => record.symbol),
          "TEST",
          "Testing",
          "SCL001",
          "AEL01",
          "ICFL1",
          "MFINCORP20",
          "MMLNCDIII",
        ]);
      })
      .catch((error) => toastr.error(error));
  }, []);

  function updatedIPOOrder(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault();
    makePOSTRequest(baseUrl + "/ipo/dbtipoorder_ipo/", currentlySelectedRow)
      .then((resp) => {})
      .catch((error) => toastr.error(error));
  }

  function handleEditIconClick(row: any) {
    setShowEditOrderDialog(true);
    setCurrentlySelectedRow(row);
  }

  function handleCellClick(params: any) {
    setUUID(params.row.uuid);
    setShowIPOOrderDetailsDialog(true);
  }

  function getScripIds(scripId: string | null) {
    if (!scripId) {
      toastr.warning("Invalid Scripid found.  Please try again later.");
      return;
    }
    const today = moment();
    const formattedDate = today.format("D MMM YYYY");
    makePOSTRequest(baseUrl + "/ipo/orderdownloadipoapiview_ipo/", {
      scripid: scripId,
      fromdate: "01 Jan 2023",
      fromtime: "23:59:59",
      todate: formattedDate,
      totime: "23:59:59",
      applicationno: "",
    })
      .then((resp) => {
        if (resp.data.length === 0) {
          toastr.warning(`There are no IPO Orders for the scripid ${scripId}`);
          return;
        }
        if ("message" in resp.data[0] && resp.data[0].message !== "") {
          toastr.error(resp.data[0].message);
          return;
        }
        setIpoOrders(
          resp.data
            .filter(
              (item: IPOOrderList) =>
                JSON.parse(localStorage.getItem("loginUser")!)?.isSuperuser ||
                JSON.parse(localStorage.getItem("loginUser")!)?.can_see_all ||
                item.leadowner ===
                  JSON.parse(localStorage.getItem("loginUser")!)?.name
            )
            .sort((a: IPOOrderList, b: IPOOrderList) => {
              const dateA = new Date(a.insertedAt);
              const dateB = new Date(b.insertedAt);
              return dateB.getTime() - dateA.getTime();
            })
        );
        setShowScripidDialog(false);
      })
      .catch((error) => toastr.error(error));
  }

  const handleDpUpiEnquiry = () => {
    setShowSpinner(true);
    let requestPayload = {
      scripid: selectedScripId,
      appno: ipoOrders
        .filter(
          (item) =>
            !item.is_processed &&
            item.applicationno.length &&
            item.allotmentstatus === "Pending Allotment" &&
            item.bids[0].bidid.length
        )
        .map((item) => item.applicationno)
        .slice(0, 300)
        .slice(0, 300),
    };
    makePOSTRequest("/ipo/dbtappenquiry_ipo/", requestPayload)
      .then((resp) => {
        if (resp.status === 200) {
          toastr.success(
            "Status Fetched Successfully. Please check individual orders for statuses."
          );
          setLastCalledOn(resp.data.timestamp);
        }
      })
      .catch((error) => {
        toastr.error(error);
      })
      .finally(() => setShowSpinner(false));
  };

  const handleUpdateScripIDs = () => {
    makeGETRequest("/ipo/list/")
      .then((resp) => {
        if (resp.status === 200) {
          toastr.success("Scrip IDs updated successfully");
          setLastCalledOn(resp.data.timestamp);
        }
      })
      .catch((error) => {
        toastr.error(error);
      });
  };

  function renderAutocomplete() {
    return (
      <Autocomplete
        id="free-solo-demo"
        freeSolo={true}
        value={selectedScripId}
        options={scripIds}
        onChange={(event, enteredValue: string | null) => {
          setSelectedScripId(enteredValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="ScripId"
            margin="normal"
            variant="outlined"
            fullWidth // Use fullWidth prop instead of style
          />
        )}
      />
    );
  }

  function handleChange(event: any) {
    event.persist();
    setCurrentlySelectedRow((previousValues: any) => ({
      ...previousValues,
      [event.target.name]: event.target.value,
    }));
  }

  return (
    <Grid className="securityTypeDataGird">
      {showExchangeMessageTicker && (
        <Ticker
          items={[exchangeMessage]}
          interval={2000}
          handleClose={() => {
            setShowExchangeMessageTicker(false);
          }}
        />
      )}
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="flex-start" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>IPO Orders</div>
            </Grid>
            <Grid item style={{ display: "flex", flexDirection: "column" }}>
              <Button
                variant="outlined"
                onClick={handleUpdateScripIDs}
                style={{
                  float: "right",
                }}
              >
                Update Scrip IDs
              </Button>
            </Grid>
            <Grid item style={{ display: "flex", flexDirection: "column" }}>
              <Button
                variant="outlined"
                onClick={handleDpUpiEnquiry}
                style={{
                  float: "right",
                }}
                disabled={showSpinner}
              >
                {showSpinner ? (
                  <CircularProgress color="primary" />
                ) : (
                  "DP UPI Enquiry"
                )}
              </Button>
              <Typography
                sx={{ fontSize: 10, color: "green", whiteSpace: "nowrap" }}
              >
                last called on{" "}
                {moment(lastCalledOn).format("(HH[H]:mm[M]) MMM DD, YYYY")}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                onClick={(event: any) => {
                  event.preventDefault();
                  setShowScripidDialog(true);
                }}
                style={{
                  float: "right",
                  cursor: "pointer",
                }}
              >
                Select ScripID
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ height: "100%", width: "100%", px: [2, 10] }}>
          <DataGrid
            components={{ Toolbar: GridToolbar }}
            rows={ipoOrders}
            columns={ipoOrdersDataGridCells}
            getRowId={(row) => row.uuid}
            onCellClick={handleCellClick}
            autoHeight
          />
        </Box>
        {showScripidDialog && (
          <GenericDialog
            show={showScripidDialog}
            heading="Select ScripID"
            showCancelButton={false}
            inputProps={renderAutocomplete()}
            onClose={(event: React.MouseEvent, reason: string) => {
              event.preventDefault();
              if (reason && reason === "backdropClick") {
                return;
              }
              setShowScripidDialog(false);
            }}
            handleSubmit={() => {
              getScripIds(selectedScripId);
            }}
          />
        )}
      </Paper>
      {showExchangeTimeTicker && (
        <Ticker
          items={[
            `Market Open Date: ${exchangeTime?.opendate} ${exchangeTime?.opentime} | Market Close Date: ${exchangeTime?.closedate} ${exchangeTime?.closetime}`,
          ]}
          interval={2000}
          handleClose={() => {
            setShowExchangeTimeTicker(false);
          }}
        />
      )}
      {showEditOrderDialog && (
        <GenericDialog
          show={showEditOrderDialog}
          showCancelButton={true}
          heading="Edit IPO Order"
          inputProps={
            <>
              <Grid container item xs={12} spacing={1}>
                <React.Fragment>
                  <Grid item xs={6} style={{ marginTop: "10px" }}>
                    <TextField
                      type="text"
                      name="quantity"
                      variant="outlined"
                      fullWidth
                      label="Quantity"
                      defaultValue={currentlySelectedRow.quantity}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6} style={{ marginTop: "10px" }}>
                    <TextField
                      type="text"
                      name="rate"
                      variant="outlined"
                      fullWidth
                      label="Rate"
                      defaultValue={currentlySelectedRow.rate}
                      onChange={handleChange}
                    />
                  </Grid>
                </React.Fragment>
              </Grid>
              <Grid container item xs={12} spacing={1}>
                <React.Fragment>
                  <Grid item xs={6} style={{ marginTop: "10px" }}>
                    <TextField
                      type="text"
                      name="series"
                      variant="outlined"
                      fullWidth
                      label="Series"
                      defaultValue={currentlySelectedRow.series}
                      onChange={handleChange}
                    />
                  </Grid>
                </React.Fragment>
              </Grid>
            </>
          }
          onClose={(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            event.preventDefault();
            setShowEditOrderDialog(false);
          }}
          handleSubmit={updatedIPOOrder}
        />
      )}
      {showIPOOrderDetailsDialog && (
        <FullScreenDialog
          show={showIPOOrderDetailsDialog}
          handleClose={(event: any) => {
            event.preventDefault();
            setShowIPOOrderDetailsDialog(false);
            setUUID("");
          }}
          showSaveButton={true}
          saveButtonText="Close"
          onSumitSaveButton={(event: any) => {}}
          inputProps={<IPOOrderDetailsDialogBody uuid={uuid} />}
          heading="IPO Order Details"
        />
      )}
    </Grid>
  );
}
