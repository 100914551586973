import React, { useEffect, useContext } from "react";
import Box from "@mui/material/Box";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Tooltip from "@mui/material/Tooltip";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, TextField } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import { appContext } from "../App";
import {
  getAllQuotes,
  addNewQuote,
  getAllISINAndSecurityNames,
  deleteQuote,
  updateExistingQuote,
  pushAllQuotes,
  pushSingleQuote,
  getTradeFloorState,
  changeTradeFloorModalState,
  deleteAllQuotesFromTradefloor,
  makePUTRequest,
  makePOSTRequest,
  makeGETRequest,
} from "../Api/api";
import DeleteModal from "../components/DeleteModal";
import ConfirmationModal from "../components/ConfirmationModal";
import Button from "@material-ui/core/Button";
import CheckIcon from "@material-ui/icons/Check";
import ClearIcon from "@material-ui/icons/Clear";
import AddQuoteModal from "../components/AddQuoteModal";
import EditIcon from "@material-ui/icons/Edit";
import EditQuoteModal from "../components/EditQuoteModal";
import SendIcon from "@material-ui/icons/Send";
import IOSSwitch from "../components/IOSSwitch";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";
import { getAppropriateErrorMessage } from "../Helpers/helpers";
import SnackbarComponent from "../components/Snackbar/Snackbar";
import EditAttributesIcon from "@material-ui/icons/EditAttributes";
import { DataGrid, GridToolbar } from "@material-ui/data-grid";
import UpdatePrivatePlacementState from "../components/Quotes/UpdatePrivatePlacementState";
import GenericDialog from "../components/Dialog/GenericDialog";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import copy from "copy-to-clipboard";
import DownloadIcon from "@mui/icons-material/Download";
import IdleTimoutConfig from "../Helpers/IdleTimeoutConfig";
import DownloadQuotesModal from "../components/Quotes/DownloadQuotesModal";
import CircularProgress from "@material-ui/core/CircularProgress";
import { green } from "@mui/material/colors";
import FavoriteIcon from "@mui/icons-material/Favorite";
import Switch from "@mui/material/Switch";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  snackBar: {
    maxWidth: 600,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  paper: {
    width: "96%",
    margin: "35px auto",
    height: " 0px",
  },
  table: {
    minWidth: 750,
  },
  margin: {
    margin: theme.spacing(1),
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function getUpdatedQuotesBasedOnPrivatePlacementParams(quotes, updated) {
  let formatted = [];
  quotes.map((quote) => {
    if (quote.uuid === updated.uuid) {
      formatted.push(updated);
    } else formatted.push(quote);
  });
  return formatted;
}

const Quotes = (props) => {
  const dataGridCells = [
    { field: "serial", headerName: "Serial", width: 190, editable: false },
    {
      field: "isPushed",
      headerName: "Is Pushed",
      width: 190,
      editable: false,
      renderCell: (booleanVal) => {
        if (booleanVal.row.isPushed === true) {
          return (
            <CheckIcon
              color="green"
              style={{
                color: "green",
                float: "right",
                "margin-top": "10px",
                "margin-right": "45px",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return (
            <ClearIcon
              color="green"
              style={{
                color: "red",
                float: "right",
                "margin-top": "10px",
                "margin-right": "45px",
                cursor: "pointer",
              }}
            />
          );
        }
      },
    },
    { field: "isin", headerName: "ISIN", width: 190, editable: false },
    {
      field: "securityName",
      headerName: "Security Name",
      width: 190,
      editable: false,
    },
    {
      field: "purchaseDate",
      headerName: "Purchase Date",
      width: 190,
      editable: false,
    },
    {
      field: "purchasePrice",
      headerName: "Purchase Price",
      width: 190,
      editable: false,
    },
    {
      field: "purchaseYield",
      headerName: "Purchase Yield",
      width: 190,
      editable: false,
    },
    { field: "spread", headerName: "Spread", width: 190, editable: false },
    {
      field: "minimumQuantity",
      headerName: "Minimum Quantity",
      width: 190,
      editable: false,
    },
    {
      field: "maximumQuantity",
      headerName: "Maximum Quantity",
      width: 190,
      editable: false,
    },
    {
      field: "isCurrentDay",
      headerName: "Is Current Day",
      width: 190,
      editable: false,
      renderCell: (booleanVal) => {
        if (booleanVal.row.isCurrentDay === true) {
          return (
            <CheckIcon
              color="green"
              style={{
                color: "green",
                float: "right",
                "margin-top": "10px",
                "margin-right": "45px",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return (
            <ClearIcon
              color="green"
              style={{
                color: "red",
                float: "right",
                "margin-top": "10px",
                "margin-right": "45px",
                cursor: "pointer",
              }}
            />
          );
        }
      },
    },
    {
      field: "isBookStock",
      headerName: "Book Stock",
      width: 190,
      editable: false,
      renderCell: (booleanVal) => {
        if (booleanVal.row.isBookStock === true) {
          return (
            <CheckIcon
              color="green"
              style={{
                color: "green",
                float: "right",
                "margin-top": "10px",
                "margin-right": "45px",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return (
            <ClearIcon
              color="green"
              style={{
                color: "red",
                float: "right",
                "margin-top": "10px",
                "margin-right": "45px",
                cursor: "pointer",
              }}
            />
          );
        }
      },
    },
    {
      field: "isHotDeal",
      headerName: "Hot Deal",
      width: 190,
      editable: false,
      renderCell: (booleanVal) => {
        if (booleanVal.row.isHotDeal === true) {
          return (
            <CheckIcon
              color="green"
              style={{
                color: "green",
                float: "right",
                "margin-top": "10px",
                "margin-right": "45px",
                cursor: "pointer",
              }}
            />
          );
        } else {
          return (
            <ClearIcon
              color="green"
              style={{
                color: "red",
                float: "right",
                "margin-top": "10px",
                "margin-right": "45px",
                cursor: "pointer",
              }}
            />
          );
        }
      },
    },
    {
      field: "sellPrice",
      headerName: "Sell Price",
      width: 190,
      editable: false,
    },
    {
      field: "sellYield",
      headerName: "Sell Yield",
      width: 190,
      editable: false,
    },
    {
      field: "edit",
      headerName: "Edit",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,

      renderCell: (row) => {
        return (
          <EditIcon
            onClick={(event) => handleEditIconOnClick(event, row)}
            color="primary"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
    {
      field: "delete",
      headerName: "Delete",
      sortable: false,
      width: 200,
      disableClickEventBubbling: true,

      renderCell: (row) => {
        return (
          <DeleteIcon
            onClick={(event) => handleDeleteIconOnClick(event, row)}
            color="primary"
            fontSize="medium"
            style={{
              float: "left",
              "margin-top": "10px",
              "margin-left": "14px",
              cursor: "pointer",
            }}
          />
        );
      },
    },

    {
      field: "pushQuote",
      headerName: "Push Quote",
      width: 190,
      editable: false,
      renderCell: (row) => {
        return (
          <SendIcon
            onClick={(event) => handlePushQuoteOnClick(event, row)}
            color="primary"
            fontSize="medium"
            style={{
              float: "right",
              "margin-top": "10px",
              "margin-right": "30px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
    {
      field: "isPrivatePlacement",
      headerName: "Is Private Placement",
      width: 190,
      editable: false,
      renderCell: (row) => {
        return (
          <EditAttributesIcon
            onClick={(event) => handleEditPrivatePlacementOnClick(event, row)}
            color="primary"
            fontSize="medium"
            style={{
              float: "right",
              "margin-top": "10px",
              "margin-right": "30px",
              cursor: "pointer",
            }}
          />
        );
      },
    },
    {
      field: "editXIRR",
      headerName: "Update XIRR",
      width: 190,
      editable: false,
      renderCell: (row) => {
        return (
          <Button
            className="btnClass"
            variant="outlined"
            onClick={(event) => handleEditXIRROnClick(event, row)}
            color="primary"
          >
            Edit XIRR
          </Button>
        );
      },
    },
  ];
  const { authUser, setAuthUser } = useContext(appContext);
  const [showFileUploadModal, setShowFileUploadModal] = React.useState(false);
  const [showPageLoader, setShowPageLoader] = React.useState(false);
  const buttonSx = {
    ...(showPageLoader && {
      bgcolor: green[500],
      "&:hover": {
        bgcolor: green[700],
      },
    }),
  };
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [quotes, setQuotes] = React.useState([]);
  const [addNewQuoteDialogState, setAddNewQuoteDialogState] =
    React.useState(false);
  const [securityNames, setSecurityNames] = React.useState([]);
  const [isinNumbers, setIsinNumbers] = React.useState([]);
  const [securityNamesISINMappings, setSecurityNamesISINMappings] =
    React.useState([]);
  const [toBeDeleted, setToBeDeleted] = React.useState(null);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [isEditMode, setIsEditMode] = React.useState(false);
  const [showDeleteAllQuotesModal, setShowDeleteAllQuotesModal] =
    React.useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState("");
  const [updatedForPrivatePlacement, setUpdatedForPrivatePlacement] =
    React.useState(null);
  const [showPrivatePlacementModal, setShowPrivatePlacementModal] =
    React.useState(false);
  const [showMissingSecurityDialog, setShowMissingSecurityDialog] =
    React.useState(false);
  const [isinsWithMissingSecurityMaster, setIsinsWithMissinsSecurityMaster] =
    React.useState([]);
  const initialState = {
    isin: "",
    securityName: "",
    purchaseDate: "",
    isCurrentDayPurchaseDate: "",
    purchasePrice: 0,
    purchaseYield: 0,
    spread: "",
    minimumQuantity: "",
    isPrivatePlacement: false,
    maximumQuantity: "",
    isCurrentDay: "",
    isBookStock: false,
    isHotDeal: false,
    sellPrice: "",
    sellYield: "",
  };
  const [showEditXIRRModal, setShowEditXIRRModal] = React.useState(false);
  const [selectedQuoteForXIRRUpdate, setSelectedQuoteForXIRRUpdate] =
    React.useState(null);
  const [addedXIRRValue, setAddedXIRRValue] = React.useState("");
  const [toBeEdited, setToBeEdited] = React.useState(null);
  const [showPushQuoteConfirmationDialog, setShowPushQuoteConfirmationDialog] =
    React.useState(false);
  const [
    showPushSingleQuoteConfirmationDialog,
    setShowPushSingleQuoteConfirmationDialog,
  ] = React.useState(false);
  const [toBePushed, setToBePushed] = React.useState(null);
  const [tradeFloorSwitchState, setTradeFloorSwitchState] =
    React.useState(false);
  const [confirmationDialogState, setConfirmationDialogState] =
    React.useState(false);
  const [showDownloadQuotesDialog, setShowDownloadQuotesDialog] =
    React.useState(false);
  const [showMarkFavSecurityDialog, setShowMarkFavSecurityDialog] =
    React.useState(false);
  const [favSecurityMasters, setFavSecurityMasters] = React.useState([]);
  const [checkedT0, setCheckedT0] = React.useState(false);
  const [checkedT1, setCheckedT1] = React.useState(false);

  function generateInputPropsForFavoriteCashflow() {
    return (
      <>
        <Autocomplete
          id="free-solo-demo"
          freeSolo={true}
          options={quotes}
          multiple={true}
          getOptionLabel={(option) => option.isin}
          getOptionSelected={(option, value) => option.uuid === value.uuid}
          onChange={(e, newVal) => {
            if (newVal.length > 3) {
              newVal.pop();
              toastr.warning(
                "You can only select up to 3 Fav. Security masters."
              );
            }
            setFavSecurityMasters([...newVal.map((quote) => quote.uuid)]);
          }}
          autoHighlight={true}
          renderInput={(params) => (
            <TextField
              {...params}
              label="ISIN ..."
              margin="normal"
              variant="outlined"
            />
          )}
        />
      </>
    );
  }

  function addSerialNumberToUsers(users) {
    let formatted = [];
    for (let iterator = 0; iterator < users.length; iterator++) {
      let userWithSerial = users[iterator];
      userWithSerial.serial = iterator + 1;
      formatted.push(userWithSerial);
    }
    return formatted;
  }

  useEffect(() => {
    makeGETRequest("/admin/quotes/favorite/list/")
      .then((resp) => {
        setFavSecurityMasters(resp.data.map((quote) => quote.isin));
      })
      .catch((error) => toastr.error(error));
  }, []);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllQuotes().then((response) => {
        if (response && response.status === 200) {
          response.data
            ? setQuotes(addSerialNumberToUsers(response.data))
            : setQuotes([]);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getTradeFloorState()
        .then((response) => {
          if (response && response.status === 200) {
            setTradeFloorSwitchState(response.data[0].isOn);
            setCheckedT0(response.data[0].checkT0);
            setCheckedT1(response.data[0].checkT1);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  useEffect(() => {
    if (authUser && authUser.uuid) {
      getAllISINAndSecurityNames()
        .then((response) => {
          if (response && response.status === 200) {
            setSecurityNames(
              response.data.map((mapping) => mapping.securityName)
            );
            setIsinNumbers(response.data.map((mapping) => mapping.isin));
            setSecurityNamesISINMappings(response.data);
          }
        })
        .catch((error) => toastr.error(error));
    }
  }, []);

  const handleDeleteIconOnClick = (row) => {
    setToBeDeleted(row);
    setShowDeleteModal(true);
  };

  const handleEditIconOnClick = (row) => {
    setToBeEdited(row);
    setIsEditMode(true);
    setAddNewQuoteDialogState(true);
  };

  const handleEditPrivatePlacementOnClick = (event, row) => {
    event.preventDefault();
    setUpdatedForPrivatePlacement(row.row);
    setShowPrivatePlacementModal(true);
  };

  const handlePushQuoteOnClick = (event, row) => {
    event.preventDefault();
    setToBePushed(row.row);
    setShowPushSingleQuoteConfirmationDialog(true);
  };

  function generateInputProps() {
    return (
      <input
        type="file"
        name="file"
        onChange={(event) => {
          setSelectedFile(event.target.files[0]);
        }}
      />
    );
  }

  function markQuotesAsFavorite(event) {
    event.preventDefault();
    makePUTRequest("/admin/quotes/mark_favorite/", {
      quote_ids: favSecurityMasters,
    })
      .then((resp) => {
        if (resp.status === 200) {
          toastr.success("Marked quotes as favorite successfully!");
          setShowMarkFavSecurityDialog(false);
        } else {
          toastr.error("Something went wrong");
        }
      })
      .catch((error) => toastr.error(error));
  }

  function updateXIRRForQuote(event) {
    event.preventDefault();
    if (addedXIRRValue === "") {
      toastr.warning("Sell Yield cannot be empty!");
      return;
    }
    makePUTRequest("/admin/quotes/xirr/update/", {
      quoteUUID: selectedQuoteForXIRRUpdate.uuid,
      updatedSellYield: addedXIRRValue,
    })
      .then((response) => {
        setQuotes(response.data);
        setShowEditXIRRModal(false);
        setSelectedQuoteForXIRRUpdate(null);
        setAddedXIRRValue("");
        toastr.success("Updated Sell Yield for quote successfully!");
      })
      .catch((error) => toastr.error(error));
  }

  function getContentForXIRRUpdate() {
    return (
      <TextField
        type="text"
        name="xirr"
        variant="outlined"
        fullWidth
        label="XIRR"
        defaultValue={addedXIRRValue}
        onChange={(event) => setAddedXIRRValue(event.target.value)}
      />
    );
  }

  function getDialogContentForMissingSecurities() {
    return (
      <Grid container spacing="1" alignItems="center">
        <Grid item>
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: 460,
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              maxHeight: 300,
              "& ul": { padding: 0 },
            }}
          >
            {isinsWithMissingSecurityMaster.map((value, index) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem key={value} disablePadding>
                  <ListItemButton
                    onClick={(event) => {
                      event.preventDefault();
                      copy(value);
                      toastr.success(
                        "ISIN value copied to clipboard successfully!"
                      );
                    }}
                  >
                    <ListItemIcon>{index + 1}</ListItemIcon>
                    <ListItemText id={labelId} primary={value} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid>
        {/* <Grid item>
          <List
            dense
            sx={{
              width: "100%",
              maxWidth: 460,
              bgcolor: "background.paper",
              position: "relative",
              overflow: "auto",
              maxHeight: 300,
              "& ul": { padding: 0 },
            }}
          >
            {isinsWithError.map((value, index) => {
              const labelId = `checkbox-list-secondary-label-${value}`;
              return (
                <ListItem key={value} disablePadding>
                  <ListItemButton
                    onClick={(event) => {
                      event.preventDefault();
                      copy(value);
                      toastr.success(
                        "ISIN value copied to clipboard successfully!"
                      );
                    }}
                  >
                    <ListItemIcon>{index + 1}</ListItemIcon>
                    <ListItemText id={labelId} primary={value} />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        </Grid> */}
      </Grid>
    );
  }

  function handleBulkUploadSubmit(event) {
    event.preventDefault();
    let formData = new FormData();
    formData.append("uploadedFile", selectedFile);
    makePOSTRequest("/admin/quotes/bulk_create/", formData)
      .then((response) => {
        toastr.success("Added all quotes successfully!");
        setQuotes(response.data.quotes);
        setShowFileUploadModal(!showFileUploadModal);
        setSelectedFile(null);
        if (response.data.isinsWithoutSecurity.length > 0) {
          setShowMissingSecurityDialog(!showMissingSecurityDialog);
          setIsinsWithMissinsSecurityMaster(response.data.isinsWithoutSecurity);
        }
        if (response.data.withErrors.length > 0) {
          setShowMissingSecurityDialog(true);
        }
      })
      .catch((error) => {
        try {
          toastr.error(error.response.data.message);
          if (error.response.data.isinsWithoutSecurity.length > 0) {
            setShowMissingSecurityDialog(!showMissingSecurityDialog);
            setIsinsWithMissinsSecurityMaster(
              error.response.data.isinsWithoutSecurity
            );
          }
          if (error.data.withErrors.length > 0) {
            setShowMissingSecurityDialog(true);
          }
        } catch (_) {
          toastr.error("There was an error");
        }
      });
  }

  function handleBulkImportClicK(event) {
    event.preventDefault();
    setShowFileUploadModal(!showFileUploadModal);
  }

  const handleAddNewQuote = (event) => {
    event.preventDefault();
    setAddNewQuoteDialogState(true);
  };

  const handleOnCloseQuoteDialog = (event) => {
    event.preventDefault();
    if (isEditMode) {
      setIsEditMode(false);
    }
    setAddNewQuoteDialogState(false);
  };

  const handleOnQuoteSubmit = (event, quote) => {
    event.preventDefault();
    addNewQuote(quote)
      .then((response) => {
        if (response && response.status === 201) {
          setQuotes(response.data);
          setAddNewQuoteDialogState(false);
          if (isEditMode) setIsEditMode(false);
          toastr.success("Quote created successfully !");
        }
      })
      .catch((error) => {
        let errorMessage = getAppropriateErrorMessage(error);
        setErrorMessage(errorMessage);
        setShowErrorSnackbar(true);
      });
  };

  const handleOnQuoteUpdate = (event, quote) => {
    event.preventDefault();
    updateExistingQuote(quote)
      .then((response) => {
        if (response && response.status === 200) {
          setQuotes(response.data);
          setAddNewQuoteDialogState(false);
          setIsEditMode(false);
          setToBeEdited(null);
          toastr.success(
            "Quote updated successfully! Please Refresh the window to see the updates."
          );
        }
      })
      .catch((error) => toastr.error(error));
  };

  const renderUpdateQuoteDialog = () => {
    if (isEditMode) {
      return (
        <EditQuoteModal
          show={addNewQuoteDialogState}
          onClose={handleOnCloseQuoteDialog}
          onUpdate={handleOnQuoteUpdate}
          isinNumbers={isinNumbers}
          securityNames={securityNames}
          mappings={securityNamesISINMappings}
          quote={toBeEdited}
        />
      );
    } else return "";
  };

  const renderAddNewQuoteDialog = () => {
    if (!isEditMode) {
      return (
        <AddQuoteModal
          show={addNewQuoteDialogState}
          onClose={handleOnCloseQuoteDialog}
          onSubmit={handleOnQuoteSubmit}
          isinNumbers={isinNumbers}
          securityNames={securityNames}
          mappings={securityNamesISINMappings}
          initialState={initialState}
          quoteIsins={quotes.map((quote) => quote.isin)}
        />
      );
    }
  };

  const handleOnCloseDeleteAllQuotesModal = (event) => {
    event.preventDefault();
    setShowDeleteAllQuotesModal(false);
  };

  const closeDeleteModal = (event) => {
    event.preventDefault();
    setShowDeleteModal(false);
    setToBeDeleted(null);
  };

  const handleOnDeleteIssuerName = (event) => {
    event.preventDefault();
    deleteQuote(toBeDeleted.uuid, authUser.uuid)
      .then((response) => {
        if (response && (response.status === 200 || response.status === 204)) {
          let filteredQuotes = quotes.filter(
            (quote) => quote.uuid !== toBeDeleted.uuid
          );
          setQuotes(filteredQuotes);
          setShowDeleteModal(false);
          setToBeDeleted(null);
          toastr.success("Deleted quote successfully !");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleOnCloseSingleQuotePushModal = (event) => {
    event.preventDefault();
    setShowPushSingleQuoteConfirmationDialog(false);
  };

  const handleOnCloseConfirmationModal = (event) => {
    event.preventDefault();
    setShowPushQuoteConfirmationDialog(false);
  };

  const handlePushSingleQuote = (event) => {
    event.preventDefault();
    pushSingleQuote({ uuid: toBePushed.uuid })
      .then((response) => {
        if (response && response.status === 200) {
          setQuotes(response.data);
          setShowPushSingleQuoteConfirmationDialog(false);
          toastr.success("Quote pushed successfully !");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const deleteAllQuotes = (event) => {
    event.preventDefault();
    deleteAllQuotesFromTradefloor()
      .then((response) => {
        if (response && response.status === 204) {
          toastr.success("Deleted All Quotes successfully!");
          setShowDeleteAllQuotesModal(false);
          setQuotes([]);
        }
      })
      .catch((error) => toastr.error(error));
  };

  const handleOnPushQuote = (event) => {
    event.preventDefault();
    pushAllQuotes()
      .then((response) => {
        if (response && response.status === 200) {
          setQuotes(response.data);
          setShowPushQuoteConfirmationDialog(false);
          toastr.success("Quote pushed successfully !");
        }
      })
      .catch((error) => toastr.error(error));
  };

  function handleEditXIRROnClick(event, row) {
    event.preventDefault();
    setShowEditXIRRModal(!showEditXIRRModal);
    setSelectedQuoteForXIRRUpdate(row.row);
    setAddedXIRRValue(row.row.sellYield);
  }

  function dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "application/pdf" });
    return blob;
  }

  function downloadFile(b64) {
    // data should be your response data in base64 format
    const blob = dataURItoBlob(b64);
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  }

  function downloadQuotesheet(event) {
    event.preventDefault();
    makePOSTRequest("admin/quotesheet/generate/", {
      name: "SMEST",
      mobile_number: "9821151915",
      email: "care@smest.in",
    })
      .then((response) => {
        downloadFile(response?.data?.download?.data);
        setShowPageLoader(false);
      })
      .catch((error) => {
        toastr.error(error);
        setShowPageLoader(false);
      });
  }

  const handlePushAllQuotesClick = (event) => {
    event.preventDefault();
    setShowPushQuoteConfirmationDialog(true);
  };

  const handleTradeFloorSwitchState = (event) => {
    event.preventDefault();
    setConfirmationDialogState(true);
    setTradeFloorSwitchState(!tradeFloorSwitchState);
  };

  const handleTradeFloorConfirmationDialogClose = (event) => {
    event.preventDefault();
    setConfirmationDialogState(false);
    setTradeFloorSwitchState(!tradeFloorSwitchState);
  };

  const handleTradeFloorConfirmationDialogConfirmation = (event) => {
    event.preventDefault();
    setConfirmationDialogState(false);
    if (authUser && authUser.uuid) {
      let payload = { ...authUser, currentState: tradeFloorSwitchState };
      if ("isSuperuser" in authUser) {
        payload.isSuperuser = authUser.isSuperuser;
      } else {
        payload.isSuperuser = false;
      }
      changeTradeFloorModalState(payload)
        .then((response) => {
          setQuotes(response.data);
          toastr.success("Changed Tradefloor state successfully ! ");
        })
        .catch((error) => toastr.error(error));
    }
  };

  const handleT0 = (event) => {
    event.preventDefault();
    setCheckedT0(event.target.checked);
    if (authUser && authUser.uuid) {
      let payload = {
        ...authUser,
        checkT0: event.target.checked,
      };
      if ("isSuperuser" in authUser) {
        payload.isSuperuser = authUser.isSuperuser;
      } else {
        payload.isSuperuser = false;
      }
      changeTradeFloorModalState(payload)
        .then((response) => {
          setQuotes(response.data);
          toastr.success("Changed T0 state successfully! ");
        })
        .catch((error) => {
          toastr.error(error);
          setCheckedT0(event.target.checked);
        });
    }
  };
  const handleT1 = (event) => {
    setCheckedT1(event.target.checked);
    if (authUser && authUser.uuid) {
      let payload = {
        ...authUser,
        checkT1: event.target.checked,
      };
      if ("isSuperuser" in authUser) {
        payload.isSuperuser = authUser.isSuperuser;
      } else {
        payload.isSuperuser = false;
      }
      changeTradeFloorModalState(payload)
        .then((response) => {
          setQuotes(response.data);
          toastr.success("Changed T1 state successfully! ");
        })
        .catch((error) => {
          toastr.error(error);
          setCheckedT1(event.target.checked);
        });
    }
  };

  const getTradeFloorSwitchState = (tradeFloorSwitchState) => {
    if (tradeFloorSwitchState) {
      return "ON";
    } else return "OFF";
  };

  const renderTradeFloorConfirmationDialog = () => {
    return (
      <div>
        <Dialog
          open={confirmationDialogState}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleTradeFloorConfirmationDialogClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Change Trade Floor State ?
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              Are you sure you want to change the trade floor state from{" "}
              {getTradeFloorSwitchState(!tradeFloorSwitchState)} to{" "}
              {getTradeFloorSwitchState(tradeFloorSwitchState)} ?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleTradeFloorConfirmationDialogClose}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={handleTradeFloorConfirmationDialogConfirmation}
              color="primary"
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  const handleDeleteAllQuotes = (event) => {
    event.preventDefault();
    setShowDeleteAllQuotesModal(true);
  };

  const handleUpdateForPrivatePlacement = (event, updatedState) => {
    event.preventDefault();
    let requestURL = "/admin/quotes/private_placement/update/";
    makePUTRequest(requestURL, updatedState)
      .then((resp) => {
        if (resp && resp.status === 200) {
          setShowPrivatePlacementModal(false);
          setUpdatedForPrivatePlacement(null);
          setQuotes(
            getUpdatedQuotesBasedOnPrivatePlacementParams(quotes, resp.data)
          );
          toastr.success("Updated Private Placement parameters successfully!");
        }
      })
      .catch((error) => toastr.error(error));
  };

  const closePrivatePlacementEditModal = (event) => {
    event.preventDefault();
    setUpdatedForPrivatePlacement(null);
    setShowPrivatePlacementModal(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setShowErrorSnackbar(false);
  };

  const currentlySelected = (params) => {
    if (params.field === "delete" && handleDeleteIconOnClick(params.row));
    else if (params.field === "edit" && handleEditIconOnClick(params.row));
  };

  return (
    <Grid className="QuotesDataGrid">
      <Paper>
        <Grid style={{ margin: "20px 0", padding: "0 20px" }}>
          <Grid container alignItems="center" spacing={3}>
            <Grid item xs>
              <div style={{ fontSize: "36px", float: "left" }}>Quotes</div>
            </Grid>
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                variant="contained"
                sx={buttonSx}
                disabled={showPageLoader}
                onClick={(event) => {
                  setShowPageLoader(true);
                  downloadQuotesheet(event);
                }}
                color="primary"
              >
                Quotesheet
              </Button>
              {showPageLoader && (
                <CircularProgress
                  size={24}
                  sx={{
                    color: green[500],
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    marginTop: "-12px",
                    marginLeft: "-12px",
                  }}
                />
              )}
            </Box>
            <FormControlLabel
              style={{
                float: "right",
                margin: "25px 10px 15px",
                cursor: "pointer",
              }}
              control={
                <IOSSwitch
                  checked={tradeFloorSwitchState}
                  onChange={handleTradeFloorSwitchState}
                  name="tradeFloorSwitchState"
                />
              }
              label={tradeFloorSwitchState === true ? "On" : "Off"}
            />

            <FormControlLabel
              style={{
                float: "right",
                margin: "25px 10px 15px",
                cursor: "pointer",
              }}
              control={
                <IOSSwitch
                  checked={checkedT0}
                  onChange={handleT0}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={"T0"}
            />
            <FormControlLabel
              style={{
                float: "right",
                margin: "25px 10px 15px",
                cursor: "pointer",
              }}
              control={
                <IOSSwitch
                  checked={checkedT1}
                  onChange={handleT1}
                  inputProps={{ "aria-label": "controlled" }}
                />
              }
              label={"T1"}
            />

            <Tooltip arrow title="Mark upto 3 Quotes as favorite.">
              <Button
                variant="outlined"
                onClick={(event) => {
                  event.preventDefault();
                  setShowMarkFavSecurityDialog(true);
                }}
                style={{
                  float: "right",
                  margin: "25px 10px 15px",
                  cursor: "pointer",
                }}
                startIcon={<FavoriteIcon />}
              >
                Mark Favorite
              </Button>
            </Tooltip>

            <Button
              className="btnClass"
              variant="outlined"
              onClick={(event) => {
                event.preventDefault();
                setShowDownloadQuotesDialog(!showDownloadQuotesDialog);
              }}
              color="primary"
              style={{
                float: "right",
                margin: "25px 10px 15px",
                cursor: "pointer",
              }}
              endIcon={<DownloadIcon />}
            >
              Download
            </Button>
            <Button
              className="btnClass"
              variant="outlined"
              onClick={(event) => handleDeleteAllQuotes(event)}
              color="primary"
              disabled={tradeFloorSwitchState === false}
              style={{
                float: "right",
                margin: "25px 10px 15px",
                cursor: "pointer",
              }}
            >
              Delete All
            </Button>

            <Button
              className="btnClass"
              variant="outlined"
              onClick={(event) => handlePushAllQuotesClick(event)}
              color="primary"
              disabled={tradeFloorSwitchState === false}
              style={{
                float: "right",
                margin: "25px 10px 15px",
                cursor: "pointer",
              }}
            >
              Push All Quotes
            </Button>

            <Button
              className="btnClass"
              variant="outlined"
              onClick={(event) => handleAddNewQuote(event)}
              color="primary"
              disabled={tradeFloorSwitchState === false}
              style={{
                float: "right",
                margin: "25px 10px 15px",
                cursor: "pointer",
              }}
            >
              Create Quote
            </Button>
            <Button
              className="btnClass"
              variant="outlined"
              onClick={handleBulkImportClicK}
              color="primary"
              disabled={tradeFloorSwitchState === false}
              style={{
                float: "right",
                margin: "25px 10px 15px",
                cursor: "pointer",
              }}
            >
              Bulk Import
            </Button>
          </Grid>
        </Grid>
        {renderAddNewQuoteDialog()}
        {renderUpdateQuoteDialog()}
        <Box
          sx={{
            height: "100vh",
            width: "100%",
            px: [2, 10],
          }}
        >
          <DataGrid
            pagination
            className="QuotesDataGrid nmm"
            components={{ Toolbar: GridToolbar }}
            rows={quotes}
            columns={dataGridCells}
            onCellClick={currentlySelected}
            getRowId={(row) => row.uuid}
          />
        </Box>
        <DeleteModal
          primaryText="Delete Quote ?"
          secondaryText="Are you sure you want to delete this Quote ?"
          isOpen={showDeleteModal}
          handleClose={closeDeleteModal}
          confirmDelete={handleOnDeleteIssuerName}
        />
        <DeleteModal
          primaryText="Delete All Quotes ?"
          secondaryText="Are you sure you want to delete all Quotes ? Please choose wisely as there is no coming back from this."
          isOpen={showDeleteAllQuotesModal}
          handleClose={handleOnCloseDeleteAllQuotesModal}
          confirmDelete={deleteAllQuotes}
        />
        <ConfirmationModal
          primaryText="Push Quote"
          secondaryText="Are you sure you want to push this quote ? "
          isOpen={showPushQuoteConfirmationDialog}
          handleClose={handleOnCloseConfirmationModal}
          onConfirmation={handleOnPushQuote}
        />
        <ConfirmationModal
          primaryText="Push Quote"
          secondaryText="Are you sure you want to push this quote ? "
          isOpen={showPushSingleQuoteConfirmationDialog}
          handleClose={handleOnCloseSingleQuotePushModal}
          onConfirmation={handlePushSingleQuote}
        />
        {renderTradeFloorConfirmationDialog()}
        {showErrorSnackbar ? (
          <SnackbarComponent
            handleClose={handleSnackbarClose}
            errorMessage={errorMessage}
          />
        ) : (
          ""
        )}{" "}
        {updatedForPrivatePlacement !== null ? (
          <UpdatePrivatePlacementState
            quote={updatedForPrivatePlacement}
            open={showPrivatePlacementModal}
            handleClose={closePrivatePlacementEditModal}
            handleUpdate={handleUpdateForPrivatePlacement}
          />
        ) : (
          ""
        )}
        {showFileUploadModal ? (
          <GenericDialog
            show={showFileUploadModal}
            heading="Upload CSV/Excel File"
            inputProps={generateInputProps()}
            onClose={(event) => {
              event.preventDefault();
              setShowFileUploadModal(!showFileUploadModal);
              setSelectedFile(null);
            }}
            handleSubmit={handleBulkUploadSubmit}
          />
        ) : (
          ""
        )}
        {showMissingSecurityDialog ? (
          <GenericDialog
            fullWidth={true}
            maxWidth="md"
            show={showMissingSecurityDialog}
            heading="Missing Security Master for these Isins"
            inputProps={getDialogContentForMissingSecurities()}
            onClose={(event) => {
              event.preventDefault();
              setShowMissingSecurityDialog(!showMissingSecurityDialog);
              setIsinsWithMissinsSecurityMaster([]);
            }}
            handleSubmit={(event) => {
              event.preventDefault();
              setShowMissingSecurityDialog(!showMissingSecurityDialog);
              setIsinsWithMissinsSecurityMaster([]);
            }}
          />
        ) : (
          ""
        )}
        {showEditXIRRModal ? (
          <GenericDialog
            show={showEditXIRRModal}
            heading={`Update XIRR for ${selectedQuoteForXIRRUpdate?.isin}`}
            inputProps={getContentForXIRRUpdate()}
            onClose={(event) => {
              event.preventDefault();
              setShowEditXIRRModal(!showEditXIRRModal);
              setSelectedQuoteForXIRRUpdate(null);
            }}
            handleSubmit={updateXIRRForQuote}
          />
        ) : (
          ""
        )}
      </Paper>
      {showDownloadQuotesDialog ? (
        <DownloadQuotesModal
          open={showDownloadQuotesDialog}
          handleClose={(event) => {
            event.preventDefault();
            setShowDownloadQuotesDialog(!showDownloadQuotesDialog);
          }}
        />
      ) : (
        ""
      )}
      {showMarkFavSecurityDialog ? (
        <GenericDialog
          show={showMarkFavSecurityDialog}
          heading="Mark upto 3 Quotes as favorite"
          inputProps={generateInputPropsForFavoriteCashflow()}
          onClose={(event) => {
            setShowMarkFavSecurityDialog(false);
          }}
          handleSubmit={markQuotesAsFavorite}
        />
      ) : (
        ""
      )}
      {/* <IdleTimoutConfig /> */}
    </Grid>
  );
};

export default Quotes;
